/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import { graphql } from 'gatsby';

import PageWrapper from 'Layouts/PageWrapper';
import SEO from 'Components/SEO';

import emailIcon from 'Assets/icons/mail.svg';

const ContactPage = ({ data }) => {
  const [ showEmail, setShowEmail ] = useState(false);
  const { contactData: { edges: [ { node: contact } ] } } = data;

  return (
    <PageWrapper>
      <SEO title={ contact.frontmatter.title } />
      <main className="page__main page__main--simple">
        <h1 className="page__title">{ contact.frontmatter.title }</h1>
        <h3 className="contact__pretitle">{ contact.frontmatter.content_pretitle }</h3>
        <h2 className="contact__title">{ contact.frontmatter.content_title }</h2>

        <div className="contact__content">

          <div className="page__main__content page__content" dangerouslySetInnerHTML={ { __html: contact.html } } />

          { !showEmail && (
            <div className="contact__mail__wrapper">
              <button type="button" onClick={ setShowEmail } className="contact__mail__button">
                Show email
              </button>
            </div>
          ) }

          { !!showEmail && (
            <div className="contact__mail__wrapper">
              <img className="contact__mail__icon" src={ emailIcon } alt="email" />
              <a className="contact__mail" href={ `mailto:${contact.frontmatter.email}` }>{ contact.frontmatter.email }</a>
            </div>
          ) }

          <div className="page__content contact__map__intro">
            <p>{ contact.frontmatter.intro_map }</p>
          </div>

          <div className="contact__map__iframe__wrapper">
            <iframe className="contact__map__iframe" src={ contact.frontmatter.iframe_src } width="100vw" height="500" frameBorder="0" style={ { border: '0' } } allowFullScreen="" aria-hidden="false" />
          </div>

        </div>


      </main>
    </PageWrapper>
  );
};

export default ContactPage;

export const query = graphql`
  query {
    contactData: allMarkdownRemark(filter: {
      frontmatter: {
        templateKey: {eq: "contact"},
      },
    }) {
      totalCount
      edges {
        node {
          html
          frontmatter {
            title
            content_pretitle
            content_title
            email
            intro_map
            iframe_src
          }
        }
      }
    }
  }
`;
